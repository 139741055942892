<template>
  <div>
    <page-title :heading="$t('erp.lang_extra') "
                :permissionAdd="this.$store.getters['permissions/checkPermission']('ware_create') && this.hybrid != 'true'" show-add-button
                url-add="/erp/baseData/garnish/add" :subheading="$t('erp.lang_extra')" :icon="icon"></page-title>
    <div class="app-main__inner">
      <garnish></garnish>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Garnish from "../../../components/erp/baseData/Garnish";
import '@mdi/font/css/materialdesignicons.min.css'

export default {
  components: {
    PageTitle,
    Garnish
  },
  data: () => ({
    icon: 'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
    hybrid: process.env.VUE_APP_HYBRID,
  })
}
</script>